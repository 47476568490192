import { Grid } from '@chakra-ui/react';
import { useInputState } from '@mantine/hooks';
import { Border, RoundedCorners, fileNameWithDate } from '@ours/utils';
import type { BrandColors } from '@ours/utils';
import { createWorkerFactory, useWorker } from '@shopify/react-web-worker';
import { useMachine } from '@xstate/react';
import { downloadABlob } from 'better-file-downloader';
import type { ChangeEventHandler, FC } from 'react';
import { memo } from 'react';

import { useAnalyticsEvent } from '../../hooks/analytics/useAnalyticsEvent';
import { useAsyncEvent } from '../../hooks/useAsyncEvent';
import { useEvent } from '../../hooks/useEvent';
import { useFocusInput } from '../../hooks/useFocusInput';
import { loadFontsForDownloading } from '../../hooks/useLoadFonts';
import { useSpeechRecognition } from '../../hooks/useSpeechRecognition';
import { createCanvas } from '../../lib/canvas/createCanvas';
import { canvasToBlob } from '../../lib/image/canvasToBlob';
import { loadImage } from '../../lib/image/loadImage';
import { insertAtCursor } from '../../lib/insertAtCursor';

import { JournalCanvas } from './lib/JournalCanvas';
import { JournalMetadata } from './lib/JournalMetadata';
import { PromptContainer } from './lib/PromptContainer';
import { journalMachine } from './lib/journalMachine';

export interface JournalProps {
  answerContentPlaceholder?: string;
  answerInitialContent?: string;
  answerInitialContentTitle?: string;
  answerTitlePlaceholder?: string;
  hideTitle?: boolean;
  onNext?: (args: { content: string; title: string }) => void;
  onNextText?: string;
  promptBgColor: BrandColors;
  promptMarkdown: string;
  promptTitle: string;
  shouldFocusInput?: boolean;
  timeInSec?: number;
}
const createWorker = createWorkerFactory(() => import('./lib/journalDownloadWorker'));

export const Journal: FC<JournalProps> = memo(
  // eslint-disable-next-line max-lines-per-function
  ({
    answerContentPlaceholder,
    answerInitialContent,
    answerInitialContentTitle,
    answerTitlePlaceholder,
    hideTitle,
    onNext,
    onNextText,
    promptBgColor,
    promptMarkdown,
    promptTitle,
    shouldFocusInput,
    timeInSec = 120,
  }) => {
    const [journalMachineState, sendMachineEvent] = useMachine(journalMachine, {
      context: { timeInSec, timeRemainingInSec: timeInSec },
    });
    const [title, setTitle] = useInputState(answerInitialContentTitle || '');
    const [content, setContent] = useInputState(answerInitialContent || '');
    const isDisabled = !!((!hideTitle && !title) || content.length < 2);
    // @ts-expect-error START_ACTION
    const canStart = journalMachineState.can('START_ACTION');
    const { trackEvent } = useAnalyticsEvent();

    const onStart = useEvent(() => {
      if (canStart) {
        trackEvent({ type: 'JOURNALING_START' });
        sendMachineEvent({ timeInSec, type: 'START_ACTION' });
      }
    });

    const onPause = useEvent(() => {
      sendMachineEvent('PAUSE_ACTION');
    });

    const onRestart = useEvent(() => {
      sendMachineEvent('RESTART_ACTION');
    });

    const onResume = useEvent(() => {
      sendMachineEvent('RESUME_ACTION');
    });

    const {
      speechRecognitionActive,
      startRecognition,
      stopRecognition,
      supportsSpeechRecognition,
    } = useSpeechRecognition();
    const textareaRef = useFocusInput<HTMLTextAreaElement>(!!shouldFocusInput);
    const onRequestVoice = useEvent(async () => {
      const txt = await startRecognition();
      const val = insertAtCursor(textareaRef.current!, txt);
      setContent(val);
    });

    const worker = useWorker(createWorker);
    const [onDownload, downloading] = useAsyncEvent(async () => {
      const canvas = createCanvas();
      await loadFontsForDownloading();
      await worker.drawCanvas(canvas, {
        content,
        lines: [],
        logo: await loadImage('/shared/journal-download-logo.png'),
        promptTitle,
        title,
      });
      trackEvent({ type: 'JOURNALING_DOWNLOAD' });
      downloadABlob((await canvasToBlob(canvas))!, {
        contentType: 'image/png',
        extension: '.png',
        fileName: fileNameWithDate(`${promptTitle} ${title}`),
      });
    });

    const onNextLocal = useEvent(() => {
      onNext?.({ content, title });
    });

    const onContentChange: ChangeEventHandler = useEvent((e) => {
      onStart();
      setContent(e);
    });
    const onTitleChange: ChangeEventHandler = useEvent((e) => {
      onStart();
      setTitle(e);
    });

    return (
      <Grid
        border={Border}
        flex="none"
        gridTemplateAreas={{ base: '"prompt""canvas""metadata"', md: '"prompt canvas metadata"' }}
        gridTemplateColumns={{ base: 'auto', md: '300px auto 160px' }}
        maxW="container.xl"
        overflow="hidden"
        w="full"
        {...RoundedCorners.Bottom}
      >
        <PromptContainer
          promptBgColor={promptBgColor}
          promptMarkdown={promptMarkdown}
          promptTitle={promptTitle}
        />
        <JournalCanvas
          answerContentPlaceholder={answerContentPlaceholder}
          answerTitlePlaceholder={answerTitlePlaceholder}
          content={content}
          hideTitle={!!hideTitle}
          onRequestVoice={onRequestVoice}
          onStopRecognition={stopRecognition}
          setContent={onContentChange}
          setTitle={onTitleChange}
          speechRecognitionActive={speechRecognitionActive}
          supportsSpeechRecognition={supportsSpeechRecognition}
          textareaRef={textareaRef}
          title={title}
        />
        <JournalMetadata
          canPause={journalMachineState.can('PAUSE_ACTION')}
          canRestart={journalMachineState.can('RESTART_ACTION')}
          canResume={journalMachineState.can('RESUME_ACTION')}
          canStart={canStart}
          isCounting={journalMachineState.context.isCounting}
          isDisabled={isDisabled}
          isDownloading={downloading}
          onDownload={onDownload}
          onNext={onNext ? onNextLocal : undefined}
          onNextText={onNextText}
          onPause={onPause}
          onRestart={onRestart}
          onResume={onResume}
          onStart={onStart}
          timeInSec={journalMachineState.context.timeInSec}
          timeRemainingInSec={journalMachineState.context.timeRemainingInSec}
        />
      </Grid>
    );
  }
);
