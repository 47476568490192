import { useEffect, useState } from 'react';

import { fontsArray } from '../components/Global/GlobalStyles';
import { CanvasFontName } from '../lib/canvas/canvasStyleFont';

export const loadFontsForDownloading = async () => {
  return await Promise.all(
    fontsArray.map((font) =>
      new FontFace(CanvasFontName, `url('/shared/fonts/${font.name}')`, {
        style: font.style,
        weight: font.weight,
      }).load()
    )
  );
};

export const useLoadFonts = () => {
  const [isLoaded, setIsLoaded] = useState(false);
  useEffect(() => {
    (async () => {
      const fonts = await loadFontsForDownloading();
      fonts.forEach((font) => document.fonts.add(font));
      setIsLoaded(true);
    })();
  }, []);

  return isLoaded;
};
