import type { FC } from 'react';
import { memo } from 'react';

export const DownloadWhite: FC = memo(() => {
  return (
    <svg fill="none" height="26" viewBox="0 0 25 26" width="25" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.3617 13.9423V2"
        stroke="currentColor"
        strokeLinecap="square"
        strokeLinejoin="round"
        strokeWidth="2.5"
      />
      <path
        d="M19.5284 9.54736L12.3619 16.4977L5.19537 9.54736"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2.5"
      />
      <rect fill="currentColor" height="4.79999" rx="2.4" width="25" y="21.2" />
    </svg>
  );
});
