import { Box, Flex } from '@chakra-ui/react';
import type { BrandColors } from '@ours/utils';
import { Border } from '@ours/utils';
import type { FC } from 'react';
import { memo } from 'react';

import { MarkDown } from '../../../components/Text/MarkDown';
import { useColorEnum } from '../../../hooks/useColorEnum';
import { MusicPlayer } from '../../MusicPlayer/MusicPlayer';

export interface PromptContainerProps {
  promptBgColor: BrandColors;
  promptMarkdown: string;
  promptTitle: string;
}

export const PromptContainer: FC<PromptContainerProps> = memo(
  ({ promptBgColor, promptMarkdown, promptTitle }) => {
    const bg = useColorEnum(promptBgColor, 'yellow');

    return (
      <Flex
        bg={bg}
        borderRight={{ base: 'none', md: Border.md }}
        flexDir="column"
        gridArea="prompt"
        justifyContent="space-between"
        p="200"
      >
        <Box>
          <Box fontWeight="600" pb="200" pt="100" textStyle="lg">
            {promptTitle}
          </Box>
          <Box textStyle="sm">
            <MarkDown value={promptMarkdown} />
          </Box>
        </Box>

        <Box display={{ base: 'none', md: 'block' }}>
          <MusicPlayer />
        </Box>
      </Flex>
    );
  }
);
