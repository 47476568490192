import type { FC } from 'react';
import { memo } from 'react';

export interface MicrophoneProps {
  color?: string;
}

export const Microphone: FC<MicrophoneProps> = memo(({ color = 'black' }) => {
  return (
    <svg fill="none" height="31" viewBox="0 0 25 31" width="25" xmlns="http://www.w3.org/2000/svg">
      <rect height="17.6667" rx="4.5" stroke={color} strokeWidth="3" width="9" x="8" y="1.5" />
      <path
        d="M23 14.7617C23 20.4687 18.299 25.0951 12.5 25.0951C6.70101 25.0951 2 20.4687 2 14.7617"
        stroke={color}
        strokeLinecap="round"
        strokeWidth="3"
      />
      <path d="M12.5 25.0952V29.5238" stroke={color} strokeWidth="3" />
      <line
        stroke={color}
        strokeLinecap="round"
        strokeWidth="3"
        x1="6.5"
        x2="18.5"
        y1="29.5"
        y2="29.5"
      />
    </svg>
  );
});
