export const insertAtCursor = (myField: HTMLTextAreaElement, insertVal: string): string => {
  if (myField.selectionStart || myField.selectionStart === 0) {
    const startPos = myField.selectionStart;
    const endPos = myField.selectionEnd;
    return (
      myField.value.substring(0, startPos) +
      insertVal +
      myField.value.substring(endPos, myField.value.length)
    );
  }

  return myField.value + insertVal;
};
