interface IOptions {
  timeoutMS?: number;
}
export const loadImage = async (src: string, options?: IOptions): Promise<HTMLImageElement> => {
  return new Promise<HTMLImageElement>((res, rej) => {
    const img = new Image();
    const id = setTimeout(() => rej(), options?.timeoutMS || 20000);
    img.crossOrigin = 'anonymous';
    img.onload = () => {
      clearTimeout(id);
      res(img);
    };
    img.src = src;
  });
};
