import { Box, Flex } from '@chakra-ui/react';
import { Colors, toClockTime } from '@ours/utils';
import { DownloadWhite, Pause, Play } from '@ours/web-icons';
import type { FC } from 'react';
import { memo } from 'react';

import { Button } from '../../../components/Buttons/Button';
import { CountdownCircle } from '../../../components/CountdownCircle/CountdownCircle';
import { UnderlinedLink } from '../../../components/Links/UnderlinedLink';

export interface JournalMetadataProps {
  canPause: boolean;
  canRestart: boolean;
  canResume: boolean;
  canStart: boolean;
  isCounting: boolean;
  isDisabled: boolean;
  isDownloading: boolean;
  onDownload: () => void;
  onNext: undefined | (() => void);
  onNextText?: string;
  onPause: () => void;
  onRestart: () => void;
  onResume: () => void;
  onStart: () => void;
  timeInSec: number;
  timeRemainingInSec: number;
}

const SmallIcon: FC<{ Icon: any; onClick: () => void }> = memo(({ Icon, onClick }) => {
  return (
    <Box>
      <Button as="button" onClick={onClick} size="roundMd" type="button">
        <Box pl="6px">
          <Icon />
        </Box>
      </Button>
    </Box>
  );
});

export const JournalMetadata: FC<JournalMetadataProps> = memo(
  ({
    canPause,
    canRestart,
    canResume,
    canStart,
    isDisabled,
    isDownloading,
    onDownload,
    onNext,
    onNextText,
    onPause,
    onRestart,
    onResume,
    onStart,
    timeInSec,
    timeRemainingInSec,
  }) => {
    return (
      <Flex
        alignItems={{ base: 'center', md: 'baseline' }}
        bg="white"
        flexDir={{ base: 'row', md: 'column' }}
        gridArea="metadata"
        justifyContent="space-between"
        pb="100"
        pt="300"
        px={{ base: '200', md: '0' }}
      >
        <Flex flexDir={{ base: 'row', md: 'column' }} gap="100" w="full">
          <Flex alignItems="center" justifyContent="center">
            <CountdownCircle
              durationInSec={timeInSec}
              elapsedTimeInSec={timeInSec - timeRemainingInSec}
              size={80}
              strokeColor={Colors.brand.red}
              strokeWidth={5}
            >
              <Box textStyle="2xs">{toClockTime(timeRemainingInSec)}</Box>
            </CountdownCircle>
          </Flex>
          <Flex alignItems="center" flexDir="column" justifyContent="center" pos="relative">
            {canStart ? <SmallIcon Icon={Play} onClick={onStart} /> : null}
            {canPause ? (
              <Box>
                <Button as="button" onClick={onPause} size="roundMd" type="button">
                  <Box pl="3px">
                    <Pause />
                  </Box>
                </Button>
              </Box>
            ) : null}
            {canResume ? <SmallIcon Icon={Play} onClick={onResume} /> : null}
            {canRestart ? (
              <Box>
                <UnderlinedLink as="button" onClick={onRestart} textStyle="2xs" type="button">
                  Restart
                </UnderlinedLink>
              </Box>
            ) : null}
          </Flex>
        </Flex>

        {onNext ? (
          <Flex alignItems="center" gap="100">
            <Box>
              <Button as="button" isDisabled={isDisabled} onClick={onNext} size="sm" type="button">
                {onNextText || 'Save'}
              </Button>
            </Box>
            <Button
              as="button"
              isDisabled={isDisabled}
              isLoading={isDownloading}
              onClick={onDownload}
              size="roundMd"
              type="button"
              variant="yellow"
            >
              <DownloadWhite />
            </Button>
          </Flex>
        ) : (
          <Flex alignItems="center" justifyContent={{ base: 'flex-end', md: 'center' }} w="full">
            <Button
              as="button"
              isDisabled={isDisabled}
              isLoading={isDownloading}
              onClick={onDownload}
              size="sm"
              type="button"
            >
              Download
            </Button>
          </Flex>
        )}
      </Flex>
    );
  }
);
