export const canvasToBlob = async (
  canvas: HTMLCanvasElement | undefined | null,
  type: 'image/png' = 'image/png'
): Promise<Blob | null> => {
  if (!canvas) {
    return null;
  }

  return await new Promise((res) => {
    canvas.toBlob(
      (blob) => {
        res(blob);
      },
      type,
      1
    );
  });
};
