import { useEffect, useState } from 'react';

import { useEvent } from './useEvent';

type SpeechRecognitionApi = {
  onresult: (res: any) => void;
  onspeechend: () => void;
  onstart: () => void;
  start: () => void;
  stop: () => void;
};

export const useSpeechRecognition = () => {
  const [supportsSpeechRecognition, setSupports] = useState(false);
  useEffect(() => {
    setSupports(
      !!(
        typeof window !== 'undefined' &&
        // @ts-expect-error speech recog is not typed
        (window.SpeechRecognition || window.webkitSpeechRecognition)
      )
    );
  }, []);
  const [rec, setRec] = useState<null | SpeechRecognitionApi>(null);

  const startRecognition: () => Promise<string> = useEvent(async (): Promise<string> => {
    return new Promise((res) => {
      // @ts-expect-error speech recog is not typed
      const speechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
      if (!speechRecognition) {
        res('');
        return;
      }

      const recognition: SpeechRecognitionApi = new speechRecognition();

      recognition.onstart = () => {
        // eslint-disable-next-line no-console
        console.log('starting listening, speak in microphone');
      };

      recognition.onspeechend = () => {
        // eslint-disable-next-line no-console
        console.log('stopped listening');
        recognition.stop();
      };

      recognition.onresult = (result) => {
        const vocalInput = result.results[0][0].transcript;
        setRec(null);
        res(vocalInput);
      };
      setRec(recognition);
      recognition.start();
    });
  });

  const stopRecognition = useEvent(async () => {
    if (rec) {
      rec.stop();
      setRec(null);
    }
  });

  return {
    speechRecognitionActive: !!rec,
    startRecognition,
    stopRecognition,
    supportsSpeechRecognition,
  };
};
