import { Box, Flex } from '@chakra-ui/react';
import { Colors } from '@ours/utils';
import { Microphone } from '@ours/web-icons';
import type { ChangeEvent, FC, RefObject } from 'react';
import { memo } from 'react';
import TextareaAutosize from 'react-textarea-autosize';

import { Tooltip } from '../../../components/Tooltip/Tooltip';

export interface JournalCanvasProps {
  answerContentPlaceholder?: string;
  answerTitlePlaceholder?: string;
  content: string;
  hideTitle: boolean;
  onRequestVoice: () => void;
  onStopRecognition: () => void;
  setContent: (e: ChangeEvent<any>) => void;
  setTitle: (e: ChangeEvent<any>) => void;
  speechRecognitionActive: boolean;
  supportsSpeechRecognition: boolean;
  textareaRef: RefObject<HTMLTextAreaElement>;
  title: string;
}

const autoResizeStyle = {
  textarea: { _focus: { border: 'none', outline: 'none' }, resize: 'none' },
};
export const JournalCanvas: FC<JournalCanvasProps> = memo(
  ({
    answerContentPlaceholder,
    answerTitlePlaceholder,
    content,
    hideTitle,
    onRequestVoice,
    onStopRecognition,
    setContent,
    setTitle,
    speechRecognitionActive,
    supportsSpeechRecognition,
    textareaRef,
    title,
  }) => {
    return (
      <Flex bg="white" flexDir="column" gridArea="canvas" pt="200" px="200">
        {hideTitle ? null : (
          <Box pb="100" pt="100" sx={autoResizeStyle} textStyle="lg">
            <TextareaAutosize
              onChange={setTitle}
              placeholder={answerTitlePlaceholder || 'Add title'}
              style={{ fontWeight: '600', width: '100%' }}
              value={title}
            />
          </Box>
        )}
        <Flex flexGrow={1} maxH={'600px'} minH={'300px'} sx={autoResizeStyle}>
          <TextareaAutosize
            maxRows={20}
            minRows={10}
            onChange={setContent}
            placeholder={answerContentPlaceholder || 'Type journal here'}
            ref={textareaRef}
            style={{ width: '100%' }}
            value={content}
          />
        </Flex>
        {supportsSpeechRecognition ? (
          <Box display={{ base: 'none', md: 'block' }} pb="100">
            {speechRecognitionActive ? (
              <button onClick={onStopRecognition}>
                <Microphone color={Colors.brand.red} />
              </button>
            ) : (
              <Tooltip label="Speech to text">
                <button onClick={onRequestVoice}>
                  <Microphone />
                </button>
              </Tooltip>
            )}
          </Box>
        ) : null}
      </Flex>
    );
  }
);
