import { useEffect, useRef } from 'react';

export const useFocusInput = <El extends HTMLTextAreaElement | HTMLInputElement>(
  shouldFocus: boolean
) => {
  const ref = useRef<El>(null);

  useEffect(() => {
    if (shouldFocus) {
      ref.current?.focus();
    }
  }, [shouldFocus]);

  return ref;
};
